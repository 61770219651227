import {Notyf} from "notyf";

const notif = new Notyf({
    position: {
        x: 'center',
        y: 'bottom'
    },
})

export default notif
